import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import ScenePicture from "../../components/picture/sceneicon";
import { Row, Col, Image, Spin } from "antd";
import Scene_add_device from "../../components/sceneedit/scene_edit_device";
import apipostDeviceInScene from "../../api/scene/postDeviceInScene";

import { getroomScene, getsceneName } from "../../stored/mydata";

export default function SceneAddRooms() {
  const Params = useParams();
  var navigate = useNavigate();
  // const dispatch = useDispatch();

  const roomScene = useSelector(getroomScene);
  const sceneName = useSelector(getsceneName);

  const [scene, setScene] = useState({});
  const [rooms, setRooms] = useState([]);
  const [obj_device, setOBJdevice] = useState({
    id: 0,
    room_name: "",
    path: "",
    device: [],
  });
  const [is_device, setIsdevice] = useState(false);

  useEffect(() => {
    setRooms(roomScene);
    // console.log("roomScene",roomScene);
    setScene(sceneName);
    // setIshow(true);
  }, []);

  const Set_data = (edit) => {
    var tmp = rooms.map((e) => {
      if (e.id === edit.id) {
        return edit;
      } else {
        return e;
      }
    });
    setRooms([...tmp]);
    createDeviceScene(tmp);
  };

  const createDeviceScene = (roomData) => {
    var data = JSON.stringify({
      scene_id: sceneName["scene_id"].toString(),
      rooms: roomData,
    });

    apipostDeviceInScene(data, (err, res) => {
      if (res.message === "Success") {
        // console.log("ok");
      }
    });
  };

  if (is_device) {
    return (
      <Scene_add_device
        data={obj_device}
        handleBack={() => {
          setIsdevice(!is_device);
          setOBJdevice({});
        }}
        handleUpdated={(event) => {
          Set_data(event);
          setIsdevice(!is_device);
          setOBJdevice({});
        }}
      />
    );
  } else {
    return (
      <div className="parentDiv">
        <div className="childDiv">
          <div style={{ backgroundColor: "#FBFBFB", height: "100%" }}>
            <div name="head" style={styles.head}>
              <Row style={{ padding: "13px 16px" }}>
                <Col span={4}>
                  <div
                    onClick={() => {
                      navigate("/scene");
                    }}
                  >
                    {ScenePicture.backpage()}
                  </div>
                </Col>
                <Col span={16} style={styles.textHead}>
                  <div>{scene.scene_name} scene</div>
                </Col>
                <Col span={4}></Col>
              </Row>
            </div>
            <div style={styles.bodyPage}>
              {rooms.map((e, i) => {
                return (
                  <div key={i + "_sceneDiv"}>
                    <Row
                      key={i + "_scene"}
                      gutter={[8, 8]}
                      style={{
                        height: "20px",
                        marginBottom: "8%",
                        padding: "0px 8px",
                      }}
                    >
                      <Col span={3} style={styles.itemInRowStart}>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            width: "35px",
                          }}
                        >
                          <Image
                            preview={false}
                            width={40}
                            src={e.path}
                            onClick={() => {
                              setOBJdevice(e);
                              setIsdevice(!is_device);
                            }}
                          />
                        </div>
                      </Col>
                      <Col span={18} style={styles.itemInRow}>
                        <div
                          style={styles.textInItem}
                          onClick={() => {
                            setOBJdevice(e);
                            setIsdevice(!is_device);
                          }}
                        >
                          {e.room_name}
                        </div>
                      </Col>
                      <Col
                        span={3}
                        style={{ textAlign: "end", paddingTop: "15px" }}
                      >
                        <div
                          onClick={() => {
                            setOBJdevice(e);
                            setIsdevice(!is_device);
                          }}
                        >
                          {ScenePicture.more_than()}
                        </div>
                      </Col>
                    </Row>
                    <hr style={styles.underlineSchedule}></hr>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const styles = {
  head: {
    backgroundColor: "#F0F9FB",
    height: "56px",
    // with: "100%",
  },
  textHead: {
    color: "#3EA8B8",
    textAlign: "center",
    fontFeatureSettings: "clig off, liga off",
    fontFamily: "Gotham",
    fontSize: "20px",
    fontStyle: "normal",
    fontWeight: "350",
    lineHeight: "30px",
    letterSpacing: "0.15px",

    whiteSpace: "nowrap",
    width: "100px",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  bodyPage: {
    // backgroundColor: "#FBFBFB",
    padding: "20px 16px 20px 16px",
    // padding: "16px",
  },
  underlineSchedule: {
    backgroundColor: "#919499",
    opacity: "0.2",
    marginTop: "10px",
  },
  itemInRow: {
    display: "flex",
    alignItems: "center",
  },
  itemInRowStart: {
    display: "flex",
    alignItems: "center",
  },
  textInItem: {
    color: "#56585D",
    fontFeatureSettings: "clig off, liga off",
    fontFamily: "Gotham",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "21px",
  },
  reload: {
    margin: "20px 0",
    marginBottom: "20px",
    padding: "30px 50px",
    textAlign: "center",
    borderRadius: "4px",
    display: "inherit",
  },
};
