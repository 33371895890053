import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Row, Col } from "antd";
import ControllerPicture from "../../components/picture/controller";
import { getcontroller, setshowCtlStored } from "../../stored/mydata";
import apipostCurtain from "../../api/controller/postCurtain";

export default function Curtain(props) {
  const dispatch = useDispatch();

  const dataController = useSelector(getcontroller);
  const [dataDevice, setDataDevice] = useState(props.data);

  useEffect(() => {
    // console.log(dataController);

    if (
      dataDevice["device_id"] === dataController["device_id"] &&
      dataDevice["mac"] === dataController["mac"]
    ) {
      setDataDevice({ ...dataDevice, device_status: dataController["status"] });
      // dataDevice["device_status"] = dataController["status"];
    }
  }, [dataController]);

  const apiControll = (value) => {
    dispatch(setshowCtlStored(true));
    const data_api = JSON.stringify(value);

    apipostCurtain(data_api, (err, res) => {
      if (res["message"] === "Success") {
        // console.log("controllerSwit");
        var count_time = 3;
        let timerId = setInterval(() => {
          count_time--;

          if (count_time === 0) {
            dispatch(setshowCtlStored(false));
            clearInterval(timerId);
          }
        }, 1000);
      }
    });
  };

  const controllerAction = (value) => {
    var data = {
      mac: dataDevice["mac"],
      device_id: dataDevice["device_id"],
      device_model: dataDevice["device_model"],
      device_type: dataDevice["device_type"],
      status: value,
    };
    apiControll(data);
  };

  return (
    <div>
      <div style={styles.card}>
        <div>
          <Row gutter={[16, 16]}>
            <Col span={7}>
              <div style={styles.fontHead}>Curtain</div>
              <div style={{ textAlign: "center" }}>
                {ControllerPicture.curtain()}
              </div>
            </Col>
            <Col span={17}>
              <Row gutter={[8, 8]}>
                <Col span={8}>
                  <div style={styles.textInbtn}>
                    <div
                      style={
                        dataDevice["device_status"] === "on"
                          ? styles.btnControllerChoose
                          : styles.btnController
                      }
                      onClick={() => {
                        controllerAction("on");
                      }}
                    >
                      <Row gutter={[8, 8]}>
                        <Col>{ControllerPicture.less()}</Col>
                        <Col>{ControllerPicture.more_than()}</Col>
                      </Row>
                    </div>
                  </div>
                </Col>
                <Col span={8}>
                  <div style={styles.textInbtn}>
                    <div
                      style={
                        dataDevice["device_status"] === "stop"
                          ? styles.btnControllerChoose
                          : styles.btnController
                      }
                      onClick={() => {
                        controllerAction("stop");
                      }}
                    >
                      <Row gutter={[8, 8]}>
                        <Col>{ControllerPicture.pause()}</Col>
                      </Row>
                    </div>
                  </div>
                </Col>
                <Col span={8}>
                  <div style={styles.textInbtn}>
                    <div
                      style={
                        dataDevice["device_status"] === "off"
                          ? styles.btnControllerChoose
                          : styles.btnController
                      }
                      onClick={() => {
                        controllerAction("off");
                      }}
                    >
                      <Row gutter={[8, 8]}>
                        <Col>{ControllerPicture.more_than()}</Col>
                        <Col>{ControllerPicture.less()}</Col>
                      </Row>
                    </div>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
}

const styles = {
  card: {
    padding: "16px",
    borderRadius: "16px",
    border: "1px solid #E3E3E3",
    // width: "318px",
    // height: "103px",
    // display: "flex",
    // flexDirection: "column",
    // alignItems: "flex-start",
    // gap: "12px",
  },
  fontHead: {
    color: "#3EA8B8",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "21px",
    textAlign: "center",
    fontFamily: "Gotham",
  },
  btnController: {
    display: "flex",
    width: "40px",
    height: "40px",
    padding: "10px",
    justifyContent: "center",
    alignItems: "center",
    flexShrink: "0",
    borderRadius: "14px",
    border: "1px solid #B5EAEF",
    // background: "#EBF4FA",
    background: "#FFF",
    boxShadow: "0px 1px 1px 0px rgba(0, 0, 0, 0.10)",
  },
  btnControllerChoose: {
    display: "flex",
    width: "40px",
    height: "40px",
    padding: "10px",
    justifyContent: "center",
    alignItems: "center",
    flexShrink: "0",
    borderRadius: "14px",
    border: "1px solid #B5EAEF",
    background: "#EBF4FA",
    // background: "#FFF",
    boxShadow: "0px 1px 1px 0px rgba(0, 0, 0, 0.10)",
  },
  textInbtn: {
    height: "100px",
    alignItems: "center",
    display: "flex",
    // padding: "0px 20px",
  },
};
