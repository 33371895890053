import React, { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import ScenePicture from "../../components/picture/sceneicon";
import apigetScene from "../../api/scene/getScene";
import { getUser } from "../../stored/mydata";
import { InfoCircleOutlined } from "@ant-design/icons";
import {
  Row,
  Col,
  Spin,
  Image,
  Layout,
  FloatButton,
  ConfigProvider,
  Modal,
} from "antd";
// import { red } from "@mui/material/colors";
const { Header, Content, Footer } = Layout;

export default function Scene(props) {
  var navigate = useNavigate();

  const myUser = useSelector(getUser);

  // const windowHeight = useRef(window.innerHeight);
  const windowWidth = useRef(window.innerWidth);

  const [scenes, setScenes] = useState([]);
  const [isshow, setIsshow] = useState(false);

  const [btnwidth, setBtnwidth] = useState(0); //380
  const [countScene, setCountscene] = useState(0);

  const [modelActive, setModelActive] = useState(false);

  useEffect(() => {
    // console.log("windowHeight", windowHeight);
    getScene();
  }, []);

  const getScene = () => {
    var data = {
      house_number: myUser.house_number,
      project_id: myUser.project_code,
      unit_number: myUser.unit_number,
    };

    apigetScene(data, (err, res) => {
      if (res.message === "Success") {
        var data_scenes = res.scenes;
        data_scenes.sort((a, b) => {
          const nameA = a.scene_name.toUpperCase();
          const nameB = b.scene_name.toUpperCase();
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        });
        setScenes(data_scenes);
        setCountscene(data_scenes.length);
        settingWidth();
      }
    });
  };

  const settingWidth = () => {
    var btn_width = 0;

    if (windowWidth.current <= 400) {
      btn_width = windowWidth.current - 350;
    } else if (windowWidth.current <= 430) {
      btn_width = windowWidth.current - 370;
    } else if (windowWidth.current <= 768) {
      btn_width = windowWidth.current - 530;
    } else if (windowWidth.current <= 1024) {
      btn_width = windowWidth.current - 650;
    }
    setBtnwidth(btn_width);
    setIsshow(true);
  };

  const dataMax = () => {
    startModal();

    var count_time = 3;
    let timerId = setInterval(() => {
      count_time--;

      if (count_time === 0) {
        stopModal();
        clearInterval(timerId);
      }
    }, 1000);
  };

  const stopModal = () => {
    setModelActive(false);
  };
  const startModal = () => {
    setModelActive(true);
  };

  return (
    <Layout>
      <Content>
        <div>
          {isshow === false ? (
            <div style={styles.reload}>
              <Spin size="large" />{" "}
            </div>
          ) : (
            <div className="parentDiv">
              <div className="childDiv" style={{ background: "#FBFBFB" }}>
                <div name="head" style={styles.head}>
                  <Row style={{ padding: "13px 16px" }}>
                    <Col span={4}>
                      <div
                        onClick={() => {
                          navigate("/home");
                        }}
                      >
                        {ScenePicture.backpage()}
                      </div>
                    </Col>
                    <Col span={16} style={styles.textHead}>
                      <div>Scene</div>
                    </Col>
                    <Col span={4}></Col>
                  </Row>
                </div>
                <div
                  style={{
                    padding: "16px",
                  }}
                >
                  {scenes.map((e, i) => {
                    return (
                      <div
                        onClick={() => {
                          navigate("/sceneedit/" + e.id);
                        }}
                        key={i + "_sceneDiv"}
                      >
                        <Row
                          key={i + "_sceneRow"}
                          style={{
                            height: "20px",
                            marginBottom: "8%",
                            padding: "0px 8px",
                          }}
                        >
                          <Col span={3} style={styles.itemInRowStart}>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              <Image
                                preview={false}
                                width={40}
                                src={e.icon_url}
                              />
                            </div>
                          </Col>
                          <Col span={18} style={styles.itemInRow}>
                            <div style={styles.textInItem}>{e.scene_name}</div>
                          </Col>
                          <Col
                            span={3}
                            style={{ textAlign: "end", paddingTop: "15px" }}
                          >
                            <div>{ScenePicture.group()}</div>
                          </Col>
                        </Row>
                        <hr style={styles.underlineSchedule}></hr>
                      </div>
                    );
                  })}
                  <ConfigProvider
                    theme={{
                      token: {
                        colorBgElevated: "#3EA8B8",
                        colorText: "white",
                      },
                    }}
                  >
                    <FloatButton
                      style={{
                        left: `${btnwidth}px`,
                        // right: `${btnwidth}px`,
                        display: "flex",
                        width: "312px",
                        height: "48px",
                        flexShrink: "0",
                        borderRadius: "8px",
                      }}
                      onClick={() => {
                        if (countScene < 10) {
                          navigate("/sceneadd");
                        } else {
                          dataMax();
                        }
                      }}
                      description={
                        <div style={styles.textInbtn}>add scene</div>
                      }
                    />
                  </ConfigProvider>
                </div>
              </div>
            </div>
          )}
        </div>

        <Modal
          centered={true}
          open={modelActive}
          footer={null}
          closable={false}
        >
          <div>
            <Row>
              <Col span={3}>
                <InfoCircleOutlined style={{ fontSize: "25px" }} />
              </Col>
              <Col span={21}>
                <div style={styles.textModelH}>Scene Limit Reached</div>
                <div style={styles.textModelB}>
                  You've reached the maximum limit for creating scenes.
                </div>
              </Col>
            </Row>
          </div>
        </Modal>
      </Content>
    </Layout>
  );
}

const styles = {
  head: {
    backgroundColor: "#F0F9FB",
    height: "56px",
  },
  textHead: {
    color: "#3EA8B8",
    textAlign: "center",
    fontFeatureSettings: "clig off, liga off",
    fontSize: "20px",
    fontStyle: "normal",
    fontWeight: "350",
    lineHeight: "30px",
    letterSpacing: "0.15px",
  },
  itemInRow: {
    display: "flex",
    alignItems: "center",
  },
  textInItem: {
    color: "#56585D",
    fontFeatureSettings: "clig off, liga off",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "21px",

    whiteSpace: "nowrap",
    width: "200px",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  itemInRowStart: {
    display: "flex",
    alignItems: "center",
    // paddingLeft: "20px",
  },
  floating_button_div: {
    position: "fixed",
    bottom: "20px",
    // float: 'left',
    // width: '33%',
    // alignItems: "center",
    right: "60px",
  },
  btnAdd: {
    display: "flex",
    width: "312px",
    height: "48px",
    padding: "9px 0px",
    justifyContent: "center",
    alignItems: "center",
    flexShrink: "0",
    borderRadius: "8px",
    border: "1px solid #3EA8B8",
    backgroundColor: "white",
    color: "#3EA8B8",
    fontSize: "14px",
    fontFamily: "Gotham",
    fontWeight: "350",
    lineHeight: "21px",
  },
  textInbtn: {
    // color: "#3EA8B8",
    textAlign: "center",
    fontFeatureSettings: "clig off, liga off",
    fontFamily: "Gotham",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "350",
    lineHeight: "21px",
    letterSpacing: "1px",
    textTransform: "uppercase",
  },
  underlineSchedule: {
    backgroundColor: "#919499",
    opacity: "0.2",
    marginTop: "10px",
  },
  reload: {
    margin: "20px 0",
    marginBottom: "20px",
    padding: "30px 50px",
    textAlign: "center",
    borderRadius: "4px",
    display: "inherit",
  },
  textModelH: {
    color: "#56585D",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "500",
    fontFamily: "Gotham",
  },
  textModelB: {
    color: "#56585D",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "300",
    fontFamily: "Gotham",
  },
};
