const setformatdata = (data, type) => {
  var set_data = data;

  if (type === "scene") {
    for (let i = 0; i < set_data.length; i++) {
      set_data[i]["device"].map((e) => {
        e["select_scene"] = "false";

        if (e["device_type"] === "lights") {
          e["device_status"] = "off";
        } else if (e["device_type"] === "ac") {
          e["device_status"] = "off";
          e["device_temp"] = "20";
          e["device_mode"] = "cool";
          e["device_fanspeed"] = "hight";
          e["device_sweep"] = "off";
        } else if (e["device_type"] === "curtains") {
          e["device_status"] = "on";
        } else if (e["device_type"] === "dim") {
          e["device_status"] = "off";
          e["device_brightness"] = "0";
        }
      });
    }
  } else if (type === "schedule") {
    for (let i = 0; i < set_data.length; i++) {
      set_data[i]["device"].map((e) => {
        e["select_schedule"] = "false";

        if (e["device_type"] === "lights") {
          e["device_status"] = "off";
        } else if (e["device_type"] === "ac") {
          e["device_status"] = "off";
          e["device_temp"] = "20";
          e["device_mode"] = "cool";
          e["device_fanspeed"] = "hight";
          e["device_sweep"] = "off";
        } else if (e["device_type"] === "curtains") {
          e["device_status"] = "on";
        } else if (e["device_type"] === "dim") {
          e["device_status"] = "off";
          e["device_brightness"] = "0";
        }
      });
    }
  }

  return set_data;
};
export default setformatdata;
