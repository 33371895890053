import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { ConfigProvider } from "antd";
import { Routes, Route, Navigate, useNavigate } from "react-router-dom";
import Layout from "./container/layoutMain/main";
import Authentication from "../src/pages/authentication";
// import Authentication from "../src/pages/authentication/indexSeverTest";
import Overview from "../src/pages/overview";
import HomePage from "../src/pages/home";
import HistoryPage from "./pages/history";
import ControllerPage from "./pages/controller";
import ScenePage from "./pages/scene";
import SchdulePage from "./pages/schedule";
import AddSchdulePage from "./pages/schedule_add";
import EditSchdulePage from "./pages/schedule_edit";
import SceneEditPage from "./pages/scene_edit";
import SceneAddPage from "./pages/scene_add_name";
import SceneRoomsPage from "./pages/scene_add_rooms";
// import SceneDevicesPage from "./pages/scene_add_devices";
import { getUserlogin } from "./stored/mydata";

// const PrivateRoute = ({ auth: { isAuthenticated }, children }) => {
//     if (userlogin) {

//     }
//     return isAuthenticated ? children : <Navigate to='/' />
// }

function Root() {
  const userlogin = useSelector(getUserlogin);

  const uuid = localStorage.getItem("Obtuuid");
//   const [isAuthenticated, setIsauthenticated] = useState(false);
  var navigate = useNavigate();

  const PrivateRoute = ({ auth: { isAuthenticated }, children }) => {
    if (userlogin) {
      return children;
    } else {
      <Navigate to="/" />;
    }
    // return isAuthenticated ? children : <Navigate to="/" />;
  };

  useEffect(() => {
    // if (uuid) {
    //   setIsauthenticated(true);
    // } else {
    //   setIsauthenticated(false);
    //   console.log(isAuthenticated);
      navigate("/");
    // }
  }, [uuid]);
  return (
    <ConfigProvider
      prefixCls="ant"
      theme={{
        components: {
          Tabs: {
            inkBarColor: "rgba(62, 168, 184, 1)",
            itemSelectedColor: "rgba(62, 168, 184, 1)",
          },
          Slider: {
            railSize: 8,
            handleActiveColor: "rgba(62, 168, 184, 1)",
          },
        },
        token: {
          /* here is your global tokens */
          colorPrimary: "rgba(62, 168, 184, 1)",
        },
      }}
    >
      <Routes>
        <Route path="/" element={<Authentication />} />

        <Route
          path="/"
          element={
            <PrivateRoute auth={{ isAuthenticated: true }}>
               <Layout />
            </PrivateRoute>
          }
        >
          <Route path="home" element={<HomePage />} />
          <Route path="history" element={<HistoryPage />} />
          <Route path="controller" element={<ControllerPage />} />
          <Route path="scene" element={<ScenePage />} />
          <Route path="schdule" element={<SchdulePage />} />
          <Route path="sceneedit/:roomid" element={<SceneEditPage />} />
          <Route path="sceneadd" element={<SceneAddPage />} />
          <Route path="scenerooms/:sceneid" element={<SceneRoomsPage />} />
          {/* <Route path="scenedevices/:sceneid/:roomid" element={<SceneDevicesPage />} /> */}
          <Route path="addschdule" element={<AddSchdulePage />} />
          <Route path="editschdule/:schduleid" element={<EditSchdulePage />} />
        </Route>
      </Routes>
    </ConfigProvider>
  );
}
export default Root;
