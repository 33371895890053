import { Switch, Space, Image, Slider } from "antd";
import ControllerPicture from "../picture/controller";
import { useEffect, useState } from "react";

export default function LightingDim(props) {
  const [data, setData] = useState({
    device_id: "",
    device_model: "",
    device_name: "",
    device_status: "",
    device_type: "",
    device_brightness: "0",
    id: 0,
    mac: "",
    select_schedule: "false",
  });

  useEffect(() => {
    if (props.data) {
      setData(props.data);
    }
    return () => {};
  }, [props.data]);

  useEffect(() => {
    props.getData(data);
    return () => {};
  }, [data]);

  return (
    <div>
      {data.select_schedule === "false" ? (
        <div style={styles.cardOff}>
          <div style={{ textAlign: "end" }}>
            <Space direction="vertical">
              <Switch
                disabled
                checked={data["device_status"] === "on" ? true : false}
                checkedChildren="ON"
                unCheckedChildren="OFF"
              />
            </Space>
          </div>
          <div
            style={{ textAlign: "center", marginTop: "15px" }}
            onClick={() => {
              setData({
                ...data,
                select_schedule: "true",
                device_status: "on",
              });
            }}
          >
            <Image
              preview={false}
              width={50}
              src={
                "https://oneapp.obotrons.com/public/img/devices/main_light.svg"
              }
            />
          </div>
          <div
            style={styles.fontHeadOff}
            onClick={() => {
              setData({
                ...data,
                select_schedule: "true",
                device_status: "on",
              });
            }}
          >
            {data["device_name"]}
          </div>
          <Slider
            defaultValue={data["device_brightness"]}
            disabled={true}
            onAfterChange={(e) => {
              console.log(e);
            }}
          />
        </div>
      ) : null}
      {data.select_schedule === "true" ? (
        <div style={styles.cardAction}>
          <div style={{ textAlign: "end" }}>
            <Space direction="vertical">
              <Switch
                onChange={(e) => {
                  let status = "off";
                  if (e) {
                    status = "on";
                  }
                  setData({ ...data, device_status: status });
                }}
                checked={data["device_status"] === "on" ? true : false}
                checkedChildren="ON"
                unCheckedChildren="OFF"
              />
            </Space>
          </div>
          <div
            style={{ textAlign: "center", marginTop: "15px" }}
            onClick={() => {
              setData({
                ...data,
                select_schedule: "false",
                device_status: "off",
              });
            }}
          >
            <Image
              preview={false}
              width={50}
              src={
                "https://oneapp.obotrons.com/public/img/devices/main_light.svg"
              }
            />
          </div>
          <div
            style={styles.fontHead}
            onClick={() => {
              setData({
                ...data,
                select_schedule: "false",
                device_status: "off",
              });
            }}
          >
            {data["device_name"]}
          </div>
          <div>
            <Slider
              defaultValue={data["device_brightness"]}
              disabled={false}
              min={0}
              max={10}
              onAfterChange={(e) => {
                setData({ ...data, device_brightness: e.toString() });
              }}
            />
          </div>
        </div>
      ) : null}
    </div>
  );
}

const styles = {
  card: {
    // fontFamily: "Gotham",
    height: "130px",
    padding: "16px",
    borderRadius: "16px",
    border: "1px solid #B5EAEF",
    background: "#FBFBFB",
  },
  cardAction: {
    // fontFamily: "Gotham",
    height: "130px",
    padding: "16px",
    borderRadius: "16px",
    border: "1px solid #B5EAEF",
    background: "#EBF4FA",
  },
  fontHead: {
    fontFamily: "Gotham",
    color: "#3EA8B8",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "21px",
    textAlign: "center",
  },
  textNumber: {
    color: "#56585D",
    textAlign: "center",
    fontFeatureSettings: "clig off, liga off",
    fontFamily: "Gotham",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "300",
    lineHeight: "21px",
    letterSpacing: "0.1px",
  },

  cardOff: {
    height: "130px",
    padding: "16px",
    borderRadius: "16px",
    border: "1px solid #E3E3E3",
    background: "#FBFBFB",
  },
  fontHeadOff: {
    fontFamily: "Gotham",
    color: "#56585D",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "21px",
    textAlign: "center",
  },
};
