import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import ScenePicture from "../picture/sceneicon";
import CardIaq from "../../components/historyIaq/cardIaq";
import apigetIaq from "../../api/home/getIaq";
import apigetRoom from "../../api/home/getRoom";
import apigetScene from "../../api/scene/getScene";
import apigetSchedule from "../../api/schedule/getSchedule";
import apipostActiveSchedule from "../../api/schedule/postActiveSchedule";
import ScenesHome from "./scenes";
import {
  setroomIdStored,
  setiaqStored,
  setemuStored,
  setcontrollerStored,
  getUser,
  setsceneAllStored,
  getcontroller,
} from "../../stored/mydata";
import SetFormatIaq from "../../api/setFormat_Iaq";
import Contact from "../controller/contact";
import moment from "moment";
import dayjs from "dayjs";

import { socket } from "../../socket";

import { Row, Col, Switch, Space, Spin, Image } from "antd";

export default function Tabroom(props) {
  var navigate = useNavigate();
  const dispatch = useDispatch();

  const myUser = useSelector(getUser);
  const dataController = useSelector(getcontroller);

  const [iaq, setIaq] = useState([]);
  const [contact, setContact] = useState([]);
  const [favorites, setFavorite] = useState([]);
  const [rooms, setRoom] = useState([]);
  const [scenes, setScenes] = useState([]);
  const [schedule, setSchedule] = useState([]);
  const [isshow, setIsshow] = useState(false);

  useEffect(() => {
    getRooms();
    getScene();
    getSchedule();

    // socket.connect();
    // socket.emit("join", `${myUser.project_code}_${myUser.house_number}`);
  }, []);

  // socket.on("message", (data) => {
  //   console.log("ok");
  //   if (JSON.stringify(dataController) !== JSON.stringify(data)) {
  //     dispatch(setcontrollerStored(data));
  //   } else {
  //     let count = 10;
  //     let timerId = setInterval(() => {
  //       count--;
  //       if (count === 0) {
  //         clearInterval(timerId);
  //       }
  //     }, 1000);
  //   }
  // });

  // socket.on("message", (data) => {
  //   console.log("ok");
  //   if (JSON.stringify(dataController) !== JSON.stringify(data)) {
  //     dispatch(setcontrollerStored(data));

  //     // contact.map((e) => {
  //     //   if (
  //     //     data["mac"] === contact["mac"] &&
  //     //     data["device_id"] === contact["device_id"]
  //     //   ) {
  //     //     const data_socket = Object.assign({}, e);
  //     //     data_socket.device_status = data["status"];
  //     //     e = data_socket;
  //     //   }
  //     // });
  //   } else {
  //     let count = 10;
  //     let timerId = setInterval(() => {
  //       count--;
  //       if (count === 0) {
  //         clearInterval(timerId);
  //       }
  //     }, 1000);
  //   }

  //   // let count = 20;
  //   // let timerId = setInterval(() => {
  //   //   count--;
  //   //   if (count == 0) {
  //   //     // console.log(data);
  //   //     dispatch(setcontrollerStored(data));
  //   //     clearInterval(timerId);
  //   //   }
  //   // }, 1000);
  //   // console.log(data);
  //   // dispatch(setcontrollerStored(data));
  //   // console.log(JSON.stringify(dataController) !== JSON.stringify(data));
  //   // if (JSON.stringify(dataController) !== JSON.stringify(data)) {
  //   // console.log(data);
  //   // let count = 5;

  //   //   let timerId = setInterval(() => {
  //   //     count--;
  //   //     if (count == 0) {
  //   //       dispatch(setcontrollerStored(data));
  //   //       clearInterval(timerId);
  //   //     }
  //   //   }, 1000);
  //   // }
  //   // dispatch(setcontrollerStored(data));
  //   // contact.map((e) => {
  //   //   if (
  //   //     data["mac"] === contact["mac"] &&
  //   //     data["device_id"] === contact["device_id"]
  //   //   ) {
  //   //     const data_socket = Object.assign({}, e);
  //   //     data_socket.device_status = data["status"];
  //   //     e = data_socket;
  //   //   }
  //   // });
  // });

  const roomid = (id) => {
    dispatch(setroomIdStored(id));
    navigate("/controller");
  };

  const GetIaq = (dataid) => {
    var data = {
      SelectedTime: "now",
      IAQ_ID: dataid,
    };
    apigetIaq(data, (err, res) => {
      if (res["message"] === "Success" && res["data"]["co2"].length > 0) {
        setIaq(SetFormatIaq(res));
      }
      setIsshow(true);
    });
  };

  const getRooms = () => {
    var data = {
      house_number: myUser.house_number,
      project_id: myUser.project_code,
      unit_number: myUser.unit_number,
    };

    apigetRoom(data, (err, res) => {
      // console.log(res.iaq);

      if (res.message === "Success") {
        var rooms = res.rooms;
        var favorite = [];

        rooms.map((e) => {
          if (e.favorite === "true") {
            favorite.push(e);
          }
        });

        dispatch(setiaqStored(res.iaq[0]));
        if (res.emu.length > 0) {
          dispatch(setemuStored(res.emu[0]));
        }

        if (res.iaq.length) {
          GetIaq(res.iaq[0]["mac"]);
        } else {
          setIsshow(true);
        }
        setContact(res.contact);

        setRoom(rooms);
        setFavorite(favorite);
      }
    });
  };

  const getScene = () => {
    var data = {
      house_number: myUser.house_number,
      project_id: myUser.project_code,
      unit_number: myUser.unit_number,
    };

    apigetScene(data, (err, res) => {
      if (res.message === "Success") {
        var scenes_all = res.scenes;
        scenes_all.map((e) => {
          e["active"] = "false";
        });

        scenes_all.sort((a, b) => {
          const nameA = a.scene_name.toUpperCase();
          const nameB = b.scene_name.toUpperCase();
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        });

        setScenes(scenes_all);
        dispatch(setsceneAllStored(scenes_all));
      }
    });
  };

  const getSchedule = () => {
    var schdule_sun = [];
    var schdule_time = [];
    var data = {
      house_number: myUser.house_number,
      project_id: myUser.project_code,
      unit_number: myUser.unit_number,
    };

    apigetSchedule(data, (err, res) => {
      if (res.message === "Success") {
        var schdule_filter = res.schedules;

        // sort mode and time
        schdule_sun = schdule_filter.filter((e) => e.schedule_mode !== "time");
        schdule_time = schdule_filter.filter((e) => e.schedule_mode === "time");

        schdule_sun.sort((a, b) => {
          const nameA = a.schedule_mode.toUpperCase();
          const nameB = b.schedule_mode.toUpperCase();
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        });

        schdule_time.sort((a, b) => {
          var timeA = a.schedule_time.split(":");
          var timeB = b.schedule_time.split(":");

          var dateA = moment("2022-04-01T17:00:00")
            .add(parseInt(timeA[0]), "hour")
            .add(parseInt(timeA[1]), "minute");
          var dateB = moment("2022-04-01T17:00:00")
            .add(parseInt(timeB[0]), "hour")
            .add(parseInt(timeB[1]), "minute");

          if (dateA < dateB) {
            return -1;
          }
          if (dateA > dateB) {
            return 1;
          }
          return 0;
        });
        // sort mode and time

        setSchedule(schdule_sun.concat(schdule_time));
      }
    });
  };

  const postActiveSchedule = (scheduleData) => {
    var data = JSON.stringify({
      schdule_id: scheduleData.id.toString(),
      cmd: scheduleData.active === "true" ? "false" : "true",
    });

    apipostActiveSchedule(data, (err, res) => {
      if (res.message === "Success") {
        navigate("/home");
      }
    });
  };

  return (
    <div>
      {isshow ? (
        <div style={{ paddingLeft: "10px", paddingRight: "10px" }}>
          {iaq.length > 0 ? <CardIaq data={iaq[0]} page={"home"} /> : null}

          {/* {contact.length ? <Contact data={contact[0]} /> : null} */}
          {contact.length
            ? contact.map((item, index) => <Contact key={index} data={item} />)
            : null}

          {favorites.length > 0 ? (
            <div name="card_favorite">
              <div style={styles.textSection}>Favorite Room</div>
              <Row gutter={[16, 16]} style={{ marginTop: "16px" }}>
                {favorites.map((e, i) => {
                  return (
                    <Col span={8} key={i + "_fvl"}>
                      <div
                        style={styles.boxCard}
                        onClick={() => {
                          roomid(e.id);
                        }}
                      >
                        <div style={{ paddingTop: "14px" }}>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <Image preview={false} width={40} src={e.path} />
                          </div>
                        </div>
                        <div style={{ marginTop: "14px" }}>
                          <div style={styles.textName}>{e.room_name}</div>
                        </div>
                      </div>
                    </Col>
                  );
                })}
              </Row>
              <hr style={styles.underline}></hr>
            </div>
          ) : null}
          {rooms.length > 0 ? (
            <div name="">
              <div style={styles.textSection}>Rooms</div>
              <Row gutter={[16, 16]} style={{ marginTop: "10px" }}>
                {rooms.map((e, i) => {
                  return (
                    <Col span={8} key={i + "_room"}>
                      <div
                        style={styles.boxCard}
                        onClick={() => {
                          roomid(e.id);
                        }}
                      >
                        <div style={{ paddingTop: "14px" }}>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <Image preview={false} width={40} src={e.path} />
                          </div>
                        </div>
                        <div style={{ marginTop: "14px" }}>
                          <div style={styles.textName}>{e.room_name}</div>
                        </div>
                      </div>
                    </Col>
                  );
                })}
              </Row>
              <hr style={styles.underline}></hr>
            </div>
          ) : null}

          <div name="card_scene">
            <Row style={{ display: "flex", justifyContent: "space-between" }}>
              <Col style={styles.textSection}>Scenes</Col>
              <Col style={styles.textSection}>
                {scenes.length > 0 ? (
                  <div
                    onClick={() => {
                      navigate("/scene");
                    }}
                  >
                    <span style={styles.textEdit}>
                      Edit {ScenePicture.pen()}
                    </span>
                  </div>
                ) : null}
              </Col>
            </Row>
            <Row gutter={[16, 16]} style={{ marginTop: "10px" }}>
              {scenes.map((e, i) => {
                return (
                  <Col span={8} key={i + "_room"}>
                    <ScenesHome data={e} />
                  </Col>
                );
              })}
              {scenes.length < 1 ? (
                <>
                  <Col span={8}>
                    <div
                      style={styles.boxCard}
                      onClick={(e) => {
                        navigate("/sceneadd");
                      }}
                    >
                      <div style={{ paddingTop: "14px" }}>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          {ScenePicture.add()}
                        </div>
                      </div>
                      <div style={{ marginTop: "14px" }}>
                        <div style={styles.textNameAdd}>Add</div>
                      </div>
                    </div>
                  </Col>
                </>
              ) : null}
            </Row>
            <hr style={styles.underline}></hr>
          </div>

          <div name="card_schedule">
            <Row style={{ display: "flex", justifyContent: "space-between" }}>
              <Col style={styles.textSection}>Schedule</Col>
              <Col style={styles.textSection}>
                {schedule.length > 0 ? (
                  <div
                    onClick={() => {
                      navigate("/schdule");
                    }}
                  >
                    <span style={styles.textEdit}>
                      Edit {ScenePicture.pen()}
                    </span>
                  </div>
                ) : null}
              </Col>
            </Row>
            {schedule.length > 0 ? (
              <div>
                {schedule.map((e, i) => {
                  return (
                    <div key={i + "_scheduleDiv"}>
                      <div style={{ padding: "8px" }} key={i + "_schedule"}>
                        <div style={styles.textTime}>
                          {e.schedule_time !== ""
                            ? e.schedule_time
                            : e.schedule_mode}
                        </div>
                        <Row gutter={[16, 16]}>
                          <Col span={16}>
                            <Row gutter={[2, 8]}>
                              <Col span={6}>
                                <div>
                                  <Image
                                    preview={false}
                                    width={50}
                                    src={e.icon_url}
                                  />
                                </div>
                              </Col>
                              <Col span={18}>
                                <div style={styles.boxStatusSchedule}>
                                  {e.schedule_name}
                                </div>
                              </Col>
                            </Row>
                          </Col>
                          <Col span={8}>
                            <Row
                              gutter={[16, 16]}
                              style={{ marginTop: "15px" }}
                            >
                              <Col span={13}>
                                <div>
                                  <Space direction="vertical">
                                    <Switch
                                      checkedChildren="ON"
                                      unCheckedChildren="OFF"
                                      onChange={() => {
                                        postActiveSchedule(e);
                                      }}
                                      defaultChecked={
                                        e.active === "true" ? true : false
                                      }
                                    />
                                  </Space>
                                </div>
                              </Col>
                              <Col span={11}>
                                <div style={styles.textStatusSchedule}>
                                  Active
                                </div>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </div>
                      <hr style={styles.underlineSchedule}></hr>
                    </div>
                  );
                })}
              </div>
            ) : null}
            {schedule.length < 1 ? (
              <Row
                gutter={[16, 16]}
                style={{ marginTop: "10px", marginBottom: "130px" }}
              >
                <Col span={8}>
                  <div
                    style={styles.boxCard}
                    onClick={(e) => {
                      if (schedule.length <= 5) {
                        navigate("/addschdule");
                      }
                    }}
                  >
                    <div style={{ paddingTop: "14px" }}>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        {ScenePicture.add()}
                      </div>
                    </div>
                    <div style={{ marginTop: "14px" }}>
                      <div style={styles.textNameAdd}>Add</div>
                    </div>
                  </div>
                </Col>
              </Row>
            ) : null}
          </div>
        </div>
      ) : (
        <div style={styles.reload}>
          <div style={styles.textSection}>
            The system is not yet available. Get started by setting up your
            smart home device.
          </div>
        </div>
      )}
    </div>
  );
}

const styles = {
  card: {
    fontFamily: "Gotham",
    borderRadius: "16px",
    background: "#FFF",
    fontSize: "18px",
  },
  textSection: {
    // fontSize: "20px",
    // color: "#56585D",
    // fontWeight: "bold",
    color: "#56585D",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "700",
    lineHeight: "24px" /* 150% */,
    letterSpacing: "0.4px",
    fontFamily: "Gotham",
  },
  boxCard: {
    borderRadius: "16px",
    height: "85px",
    flexShrink: "0",
    backgroundColor: "#eaf5fc",
    padding: "7px",
    fontFamily: "Gotham",
  },
  boxCardActive: {
    borderRadius: "16px",
    height: "85px",
    flexShrink: "0",
    backgroundColor: "#eaf5fc",
    padding: "7px",
    fontFamily: "Gotham",
    borderStyle: "solid",
    borderColor: "#3EA8B8",
  },
  textName: {
    color: "#3EA8B8",
    textAlign: "center",
    fontFeatureSettings: "clig off, liga off",
    fontSize: "11px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "12px",
    letterSpacing: "0.2px",
    textTransform: "uppercase",
    fontFamily: "Gotham",

    whiteSpace: "nowrap",
    width: "93px",
    overflow: "hidden",
    textOverflow: "ellipsis",

    // whiteSpace: "nowrap",
    // overflow: "hidden",
    // textOverflow: "ellipsis",
  },
  textNameAdd: {
    color: "#3EA8B8",
    textAlign: "center",
    fontFeatureSettings: "clig off, liga off",
    fontSize: "11px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "12px",
    letterSpacing: "0.2px",
    fontFamily: "Gotham",
  },
  underline: {
    backgroundColor: "#919499",
    opacity: "0.2",
    marginTop: "24px",
  },
  underlineSchedule: {
    backgroundColor: "#919499",
    opacity: "0.2",
    marginTop: "10px",
  },
  textEdit: {
    color: "#56585D",
    fontFeatureSettings: "clig off, liga off",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "24px",
    letterSpacing: "0.4px",
  },
  textTime: {
    color: "#56585D",
    // textAlign: "center",
    fontFeatureSettings: "clig off, liga off",
    fontSize: "24px",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "21px",
  },
  boxStatusSchedule: {
    marginTop: "8px",
    padding: "4px 8px",
    borderRadius: "6px",
    whiteSpace: "nowrap",
    width: "150px",
    overflow: "hidden",
    textOverflow: "ellipsis",
    color: "#3EA8B8",
  },
  textboxStatus: {
    color: "#3EA8B8",
    textAlign: "center",
    fontFeatureSettings: "clig off, liga off",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "22px",
  },
  textStatusSchedule: {
    color: "#56585D",
    paddingTop: "4px",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "normal",
  },
  reload: {
    margin: "20px 0",
    marginBottom: "20px",
    padding: "30px 50px",
    textAlign: "center",
    borderRadius: "4px",
    display: "inherit",
  },
};
